<template>
  <div class="follow-form">
        <van-action-sheet class="follow-sheet" v-model="show">
            <div style="padding: 14px 14px 0 14px; background: #fff; margin-bottom: 9px; min-height: 3.49rem;">
                <div class="letter-con" v-if="smsContent">
                    {{ smsContent }}
                </div>
                <div class="letter-con" style="font-size: 12px; text-align: center; line-height: 2.76rem;" v-else>
                    暂无数据
                </div>
            </div>
            <div style="width: 93%; margin: 0 auto; background: #15AEA2; border: 6px solid #ADE3DD; border-radius: 15px; position: relative; z-index: 1; bottom: -6px;"></div>
            <div style="padding: 0 12px; z-index: 1000; position: relative;">
                <van-form ref="form" class="form" @submit="onSubmit">
                    <HSelect ref="trackStatus" :config="config.trackStatus" @input="trackStatusInput" />
                    <HDatePicker ref="nextTrackDate" :config="config.nextTrackDate" />
                    <van-cell v-if="config.lossReason.required" style="position: relative;">
                        <template #title>
                            <span style="color: red; position: relative; right: 5px;">*</span>
                            <span style="color: rgb(112, 112, 112); font-size: 14px;">流失原因</span>
                        </template>
                    </van-cell>
                    <HInput ref="lossReason" v-if="config.lossReason.required" class="task-text" :config="config.lossReason" />
                    <HSelect ref="repairType" :config="config.repairType" />
                    <HSelect ref="insuredSource" :config="config.insuredSource" />
                    <van-cell style="position: relative;">
                        <template #title>
                            <span style="color: red; position: relative; right: 5px;">*</span>
                            <span style="color: rgb(112, 112, 112); font-size: 14px;">跟进记录</span>
                        </template>
                    </van-cell>
                    <HInput ref="trackContent" class="task-text" :config="config.trackContent" />
                    <div class="follow-tag" style="font-size: 12px;">
                        <HTag :list="followTags" :id="id" :maxNum="5" @close="close" @getTagList="getTagList" @updateRecord="updateRecord" />
                    </div>
                    <div style="padding: 16px; display: flex; justify-content: space-around;">
                        <van-button round block type="info" native-type="button" @click="show = false; form = {}" style="width: 40%; display: inline-block; background: #fff; color: #15AEA2; border: 1px solid #15AEA2;">取消</van-button>
                        <van-button round block type="info" color="#15AEA2" native-type="submit" style="width: 40%; display: inline-block;">确定</van-button>
                    </div>
                </van-form>
            </div>
        </van-action-sheet>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from "vuex";
import { Notify, Toast } from 'vant'
import { request } from "@/api/service";
import HSelect from '@/components/form-items/HSelectAccount.vue'
import HDatePicker from '@/components/form-items/HDatePicker.vue'
import HInput from '@/components/form-items/HInputAccount.vue'
import HTag from '@/components/form-items/HTag.vue'
import store from '@/store'
export default {
	name:'FollowForm',
	components: { HSelect, HDatePicker, HInput, HTag },
    props: {
        id: {
            type: String,
            default: ''
        },
        smsContent: {
            type: String,
            default: ''
        }
    },
    provide () {
		return {
			pageContext: this.pageContext
		}
	},
    created () {
        this.init()
    },
	data() {
		return {
            pageContext: this,
            show: false,
            form: {
                trackStatus: '',
                trackStatusName: '',
                nextTrackDate: '',
                nextTrackDateStr: '',
                trackIntent: '',
                trackIntentName: '',
                lossReason: '',
                lossReasonName: '',
                trackContent: '',
                repairType: '',
                repairTypeName: '',
                insuredSource: '',
                insuredSourceName: ''
            },
            config: {
                trackStatus: {
                    val: '1',
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '跟进状态',
                    required: true,
                    dictCode: 'REPAIR_SMS_TRACK_STATUS',
                    rules: [
                        {
                            required: true,
                            message: "跟进状态必填",
                            trigger: "change",
                        }
                    ]
                },
                nextTrackDate: {
                    val: null,
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '下次跟进日期',
                    required: false,
                    dictCode: 'LOSS_REASON',
                    minDate: new Date(),
                    rules: [
                        {
                            required: true,
                            message: "下次跟进日期必填",
                            trigger: "change",
                        }
                    ]
                },
                repairType: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '维修方式',
                    required: true,
                    dictCode: 'REPAIR_TYPE',
                    rules: [
                        {
                            required: true,
                            message: "维修方式必填",
                            trigger: "change",
                        }
                    ]
                },
                insuredSource: {
                    val: '1',
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '投保来源',
                    required: true,
                    dictCode: 'OUR_POLICY_FLAG',
                    rules: [
                        {
                            required: true,
                            message: "投保来源必填",
                            trigger: "change",
                        }
                    ]
                },
                trackContent: {
                    val: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请输入',
                    label: '',
                    required: true,
                    dictCode: null,
                    type: 'textarea',
                    maxlength: 300,
                    rules: [
                        {
                            required: true,
                            message: "跟进记录必填",
                            trigger: "change",
                        }
                    ]
                },
                lossReason: {
                    val: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请输入',
                    label: '',
                    required: false,
                    dictCode: null,
                    type: 'textarea',
                    maxlength: 300,
                    rules: [
                        {
                            required: true,
                            message: "流失原因必填",
                            trigger: "change",
                        }
                    ]
                },
            },
            followTags: []
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"])
    },
	async created(){
        store.dispatch('dict/loadDict')
	},
	mounted () {
    },
	methods:{
		dayjs,
        onSubmit () {
            request({
                url: `/afis-renewal/repair/repairSmsTrack/save`,
                method: "post",
                data: {
                    repairSmsId: this.id,
                    trackStatus: this.config.trackStatus.val,
                    nextTrackDate: this.config.nextTrackDate.val,
                    insuredSource: this.config.insuredSource.val,
                    lossReason: this.config.lossReason.val,
                    trackContent: this.config.trackContent.val,
                    repairType: this.config.repairType.val
                }
            }).then(res => {
                Toast.success('跟进成功')
                this.$emit('updateList', this.id)
                this.show = false
            })
        },
        trackStatusInput (val) {
            this.$refs.form.resetValidation()
            if (val === '1') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['trackIntent', 'nextTrackDate', 'trackStatus', 'repairType'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            } else if (val === '2') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['insuredSource', 'trackContent', 'trackStatus', 'repairType'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            } else if (val === '4') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['repairType', 'trackContent', 'trackStatus'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            } else if (val === '3') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['lossReason', 'trackStatus'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            }
        },
        close () {
           this.getTagList()
        },
        getTagList () {
            this.$refs.form && this.$refs.form.resetValidation()
            request({
                url: `/afis-engine/label/cfgLabel/listByUse`,
                method: "post",
                data: {
                }
            }).then(res => {
                this.followTags = res
            })
        },
        updateRecord (labelName) {
            this.config.trackContent.val += labelName
            this.config.trackContent.val = this.config.trackContent.val.replace('null', '')
        },
        init () {
            for (let i in this.config) {
                this.config[i].val = null
            }
            this.config.trackStatus.val = '2'
            this.config.insuredSource.val = '1'
            this.config.repairType.val = '1'
            this.config.lossReason.required = false
            this.getTagList()
            this.$refs.form && this.$refs.form.resetValidation()
        }
	}
}
</script>
<style lang="scss" scoped>
.follow-form ::v-deep {
    .van-field__body input {
        text-align: right;
    }
    .letter-con {
        margin: 0 auto;
        line-height: 20px;
        background-color: #15AEA2;
        padding: 14px;
        color: #FFFFFF;
        letter-spacing: 0.68px;
        font-size: 13px;
        text-align: justify;
        box-shadow: 4px 4px 8px 0;
        border-radius: 12px 12px 12px 0;
        min-height: 2.76rem;
    }
    .follow-tag {
        padding-left: 16px;
    }
    .task-text::before {
        display: none;
    }
    .task-text {
        padding-top: 0px;
        .van-cell__value {
            padding: 10px;
            background: #F2F2F6;
        }
    }
    .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
        color: #CDCDDB;
    }
    .van-action-sheet {
        max-height: 100%;
    }
    .van-overlay {
        background: #fff;
    }
    .van-popup--bottom.van-popup--round {
        border-radius: unset;
        background: #EFEFF9;
    }
    .form {
        background: #fff;
    }
}
</style>