<template>
  <div class="task" id="task">
    <van-sticky>
        <ListSearch :listConfig="listConfig" @search="search" @analysis="analysis" style="position: fixed; z-index: 1000; width: 100%;" />
    </van-sticky>
	<HYList ref="HYList" v-bind="listConfig" style="position: relative; top: 113px;" >
		<template #columnHead="{ row }">
			<div style="height: 2.2rem;">
                <div class="carInfo">
                    <p class="plateNo">
                        {{ row.plateNo }}
                        <a :href="'tel:'+row.reportPhone" class="phone-img">
                            <van-image
                                style="height: 0.5rem; width: 0.5rem;"
                                :src="require('@/assets/images/phone.png')"
                            />
                        </a>
                    </p>
                </div>
                <div class="line"></div>
                <div class="userInfo">
                    <div class="userInfoCon" @click="toDetail(row.id)">
                        <p>
                            <span class="title">车主姓名：</span>
                            <span class="titledes">{{ row.reportName }}</span>
                        </p>
                        <p style="margin-top: 0.2rem;">
                            <span class="title">到期时间：</span>
                            <span class="titledes">{{ dayjs(row.policyEndTime).format('YYYY-MM-DD') }}</span>
                        </p>
                    </div>
                    <van-image
                        class="status-img"
                        v-if="row.lastTrackStatus === '0'"
                        :src="require('@/assets/images/taskStatus0.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.lastTrackStatus === '1'"
                        :src="require('@/assets/images/taskStatus1.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.lastTrackStatus === '2'"
                        :src="require('@/assets/images/taskStatus7.svg')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.lastTrackStatus === '3'"
                        :src="require('@/assets/images/taskStatus4.png')"
                        @click.stop="follow(row)"
                    />
                    <van-image
                        class="status-img"
                        v-if="row.lastTrackStatus === '4'"
                        :src="require('@/assets/images/taskStatus8.svg')"
                        @click.stop="follow(row)"
                    />
                </div>
			</div>
		</template>
		<template #columnFoot="{}">
		</template>
		<template #empty>
			<div class="none-tip">
				<van-image
				:src="require('@/assets/images/none_data.jpg')"
				/>
				<p>尊敬的用户，您当前暂无短推信息</p>
			</div>
		</template>
	</HYList>
    <LetterAnalysis ref="LetterAnalysis" @updateList="updateList" />
    <FollowForm ref="FollowForm" :id="taskId" :smsContent="smsContent" @updateList="updateList" />
  </div>
</template>
<script>
import HYList from '@/components/List/index'
import dayjs from 'dayjs'
import { mapState } from "vuex";
import ListSearch from './components/listSearch'
import LetterAnalysis from './module/letterAnalysis.vue'
import FollowForm from './module/followForm.vue'
export default {
	name:'OrderList',
	components: { HYList, ListSearch, LetterAnalysis, FollowForm },
	provide () {
		return {
			pageContext: this.pageContext
		}
	},
	data() {
		return {
			listConfig:{
				api:{
					url:'/afis-renewal/repair/repairSms/listByWX'
				},
				form:{
					queryCondition:'',
                    trackStatus: null
				},
				column:[],
                styleObj: {}
			},
            taskId: '',
            smsContent: ''
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"])
    },
	async created(){
	},
	mounted () {
        task.style.height = (document.documentElement.clientHeight - 60) + 'px'
    },
	methods:{
		dayjs,
        search () {
            this.$refs.HYList.onRefresh()
        },
        toFollow (row) {
            this.$router.push({
                path: '/follow',
                query: {
                   id: row.taskId 
                }
            })
        },
        analysis () {
            this.$refs.LetterAnalysis.show = true
            this.$refs.LetterAnalysis.init()
        },
        toDetail (id) {
            this.$router.push({
                path: '/shortDetail',
                query: {
                    id: id
                }
            })
        },
        updateList () {
            this.$refs.HYList.onRefresh()
        },
        follow (row) {
            this.taskId = row.id
            this.smsContent = row.smsContent
            this.$refs.FollowForm.show = true
            this.$refs.FollowForm.init()
        }
	}
}
</script>
<style lang="scss" scoped>
.task {
    overflow: auto;
    p {
        margin: 0;
        padding: 0;
    }
    .none-tip {
        position: relative;
        top: 20%;
        text-align: center;
        p {
            margin-top: 10px;
            text-align: center;
        }
    }
    .carInfo {
        height: 0.8rem;
        line-height: 0.8rem;
        padding-left: 0.245rem;
        p {
            margin: 0;
            padding: 0;
        }
        .plateNo {
            font-size: 0.3rem;
            position: inherit;
            .surplus {
                color: #37B8AD;
                font-size: 0.24rem;
                display: inline-block;
                width: 80px;
                border: 1px solid #37B8AD;
                height: 0.4rem;
                line-height: 0.4rem;
                text-align: center;
                border-radius: 0.04rem;
            }
            .phone-img {
                height: 0.5rem; width: 0.5rem; position: absolute; right: 0.31rem; top: 0.15rem;
            }
        }
    }
    .line {
        border-bottom: 1px solid rgba(151,151,151, .3);
    }
    .userInfo {
        padding-left: 0.245rem;
        margin-top: 0.175rem;
        .userInfoCon {
            height: 0.37rem;
            line-height: 0.37rem;
            font-size: 0.26rem;
        }
        .title {
            color: #B6B9BF;
            font-size: 0.26rem;
            display: inline-block;
            width: 1.3rem;
            text-align: left;
        }
        .titledes {
            font-size: 0.26rem;
            color: #1B1B1B;
            display: inline-block;
            width: 160px;
            vertical-align: bottom;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .status-img {
            width: 1.2rem;
            height: 1.2rem;
            width: 1.2rem;
            height: 1.2rem;
            position: absolute;
            right: 0.84rem;
            bottom: 0.1rem;
        }
    }
}
</style>