<template>
  <div class="letter-form">
        <van-action-sheet title="短信解析" v-model="show">
            <van-form ref="form" @submit="onSubmit">
                <HInput ref="smsContent" class="analysis-textarea" :config="config.smsContent">
                    <template #button>
                        <van-button round block @click="toAnalysis" native-type="button" type="info" color="#15AEA2" style="width: 80px; display: inline-block; background: #fff; height: 29px; position: absolute; bottom: 8px; right: 8px;">解析</van-button>
                    </template>
                </HInput>
                <HInput ref="reportName" :config="config.reportName" />
                <HInput ref="reportPhone" :config="config.reportPhone" />
                <HInput ref="plateNo" :config="config.plateNo" />
                <HSelect ref="insCompanyCode" :config="config.insCompanyCode" />
                <HDatePicker ref="reportTime" :config="config.reportTime" />
                <HSelect ref="repair" :config="config.repair" />
                <HSelect ref="allotUserId" :config="config.allotUserId" />
                <div style="margin: 16px; display: flex; justify-content: space-around;">
                    <van-button round block type="info" color="#15AEA2" native-type="submit" style="width: 40%; display: inline-block;">确定</van-button>
                </div>
            </van-form>
        </van-action-sheet>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from "vuex";
import { Notify, Toast } from 'vant'
import { request } from "@/api/service";
import HSelect from '@/components/form-items/HSelectAccount.vue'
import HDatePicker from '@/components/form-items/HDatePicker.vue'
import HInput from '@/components/form-items/HInputAccount.vue'
import HTag from '@/components/form-items/HTag.vue'
export default {
	name:'LetterAnalysis',
	components: { HSelect, HDatePicker, HInput },
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    provide () {
		return {
			pageContext: this.pageContext
		}
	},
    created () {
    },
	data() {
		return {
            pageContext: this,
            companyShow: false,
            reportTimeShow: false,
            createTimeShow: false,
            repairShow: false,
            allotUserShow: false,
            show: false,
            form: {
                reportName: '',
                reportPhone: '',
                plateNo: '',
                insCompanyCode: '',
                insCompanyName: '',
                reportTime: '',
                reportTimeStr: '',
                createTime: '',
                createTimeStr: '',
                repair: '',
                repairName: '',
                allotUserId: '',
                allotUserName: '',
                smsContent: ''
            },
            companyList: [],
            userList: [],
            config: {
                smsContent: {
                    val: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '粘贴短信信息，自动拆分关键信息',
                    label: '',
                    required: false,
                    dictCode: null,
                    type: 'textarea',
                    rows: 5,
                    maxlength: 500,
                    rules: [
                    ]
                },
                reportName: {
                    val: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请输入',
                    label: '姓名',
                    required: true,
                    dictCode: null,
                    type: 'text',
                    maxlength: 10,
                    rules: [
                        {
                            required: true,
                            message: "姓名必填",
                            trigger: "change",
                        }
                    ]
                },
                reportPhone: {
                    val: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请输入',
                    label: '联系方式',
                    required: true,
                    dictCode: null,
                    type: 'text',
                    maxlength: 20,
                    rules: [
                        {
                            required: true,
                            message: "联系方式必填",
                            trigger: "onBlur"
                        },
                        {
                            message: "联系方式格式有误",
                            trigger: "onBlur",
                            pattern: /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/
                        }
                    ]
                },
                plateNo: {
                    val: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    inputEvent: null,
                    toUpperCase: true,
                    placeholder: '请输入',
                    label: '车牌号',
                    required: true,
                    dictCode: null,
                    type: 'text',
                    maxlength: 10,
                    rules: [
                        {
                            required: true,
                            message: "车牌号必填",
                            trigger: "change",
                        },
                        {
                            trigger: "onBlur",
                            message: "车牌号格式有误",
                            pattern: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳使领])|(临[A-Z0-9]{6}))$/
                        }
                    ]
                },
                insCompanyCode: {
                    val: null,
                    insCompanyName: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '保险公司',
                    required: true,
                    dictCode: '',
                    apiCode: '/afis-renewal/ins/company/org/propHead/dict/listAll',
                    inputAlign: 'right',
                    rules: [
                        {
                            required: true,
                            message: "保险公司必填",
                            trigger: "change",
                        }
                    ],
                    confirmEvent: (ctx) => {
                        const { currentText } = ctx;
                        this.config.insCompanyCode.insCompanyName = currentText
                    },
                },
                reportTime: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '报案时间',
                    required: true,
                    dictCode: 'LOSS_REASON',
                    type: 'datetime',
                    format: 'YYYY-MM-DD HH:mm',
                    inputAlign: 'right',
                    rules: [
                        {
                            required: true,
                            message: "报案时间必填",
                            trigger: "change",
                        }
                    ]
                },
                repair: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '维修方式',
                    required: true,
                    dictCode: 'REPAIR_TYPE',
                    inputAlign: 'right',
                    rules: [
                        {
                            required: true,
                            message: "维修方式必填",
                            trigger: "change",
                        }
                    ]
                },
                allotUserId: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'id',
                    labelKey:'alias',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '理赔专员',
                    required: true,
                    dictCode: '',
                    apiCode: '/afis-auth/auth/user/queryUserListByRoleCodes',
                    apiMethod: 'post',
                    inputAlign: 'right',
                    apiData:  {
                        roleCodes: ["R_SA"]
                    },
                    rules: [
                        {
                            required: true,
                            message: "理赔专员必填",
                            trigger: "change",
                        }
                    ]
                }
            }
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"])
    },
	async created(){
	},
	mounted () {
    },
	methods:{
		dayjs,
        statusCli () {
            this.trackStatusShow = true
        },
        onSubmit () {
            let allotUserName = null
            if (this.$refs.allotUserId.options.length > 0) {
                allotUserName = this.$refs.allotUserId.options.filter(item => item.id)[0].alias
            }
            request({
                url: `/afis-renewal/repair/repairSms/save`,
                method: "post",
                data: {
                    reportName: this.config.reportName.val,
                    reportPhone: this.config.reportPhone.val,
                    plateNo: this.config.plateNo.val,
                    insCompanyCode: this.config.insCompanyCode.val,
                    insCompanyName: this.config.insCompanyCode.insCompanyName,
                    reportTime: this.config.reportTime.val,
                    repairType: this.config.repair.val,
                    allotUserId: this.config.allotUserId.val,
                    allotUserName: allotUserName,
                    smsContent: this.config.smsContent.val
                }
            }).then(res => {
                Toast.success('操作成功')
                this.show = false
                this.$emit('updateList')
            })
        },
        toAnalysis () {
            request({
                url: `/afis-renewal/repair/cfgSmsTemplate/parseSms`,
                method: "post",
                data: {
                    smsText: this.config.smsContent.val
                }
            }).then(res => {
                if (res) {
                    for (let i in this.config) {
                        this.config[i].val = res[i]
                    }
                    this.config.repair.val = '4'
                    this.$refs.form.resetValidation()
                    Toast.success('解析成功')
                }
            })
        },
        init () {
            for (let i in this.config) {
                this.config[i].val = null
            }
            this.config.repair.val = '1'
            this.$refs.form && this.$refs.form.resetValidation()
        }
	}
}
</script>
<style lang="scss" scoped>
.letter-form ::v-deep {
    .analysis-textarea::before {
        display: none;
    }
    .analysis-textarea {
        padding-top: 0px;
        .van-cell__value {
            padding: 10px;
            background: #F2F2F6;
        }
    }
    .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
        color: #CDCDDB;
    }
}
</style>